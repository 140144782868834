import { getCsvParticipants } from "@api/doinsport/services/bookings/participant/participant.api";

export default {
  getBookingParticipantsCsv: (context, bookingId) => {
    return new Promise((resolve, reject) => {
      getCsvParticipants(bookingId)
        .then((response) => {
          resolve(response.data);
        })
        .catch((err) => reject(err))
      ;
    });
  },
};