import state from "@/store/modules/multiple-booking/state";
import getters from "@/store/modules/multiple-booking/getters";
import actions from "@/store/modules/multiple-booking/actions";
import mutations from "@/store/modules/multiple-booking/mutations";

export default {
  namespaced: true,
  state: state,
  getters: getters,
  actions: actions,
  mutations: mutations,
};