<template>
  <b-modal
    v-model="display"
    hide-header
    hide-footer
    no-close-on-esc
    hide-header-close
    no-close-on-backdrop
    class="m-0"
    size="xl"
    header-class="my-second-class"
    body-bg-variant="gray-lighten"
  >
    <b-row>
      <b-col
        class="modal-title-class text-center pl-4 pr-4 d-flex justify-content-center"
        cols="12"
      >
        {{ $t('caisse.caisse-list') }}
      </b-col>
    </b-row>
    <div class="border-bottom-grey-dark mb-2" />

    <b-row class="mt-3">
      <b-col
        v-for="(session, i) in sessions"
        :key="'session'+ i"
      >
        <b-card
          class="cash-card"
        >
          <img
            :src="sessionIcon(session.id)"
          >
          <label
            class="cash-label"
            :data-type="sessionSelection(session.id)"
          >
            {{ session.username }}
          </label>
          <label
            class="cash-label"
            :data-type="sessionSelection(session.id)"
          >
            {{ session.siret }}
          </label>
          <d-button
            v-if="sessionSelection(session.id) === 'not-selected'"
            text="caisse.select-caisse"
            icon="fa fa-flash"
            icon-position="left"
            class="btn-text-to-display d-btn-sm btn d-btn-refund font-text-title ml-2"
            @on:button-click="onSelect(session)"
          />
          <d-button
            v-else
            text="caisse.devices"
            icon="fa fa-power-off"
            icon-position="left"
            class="btn-text-to-display d-btn-sm btn d-btn-success-selected font-text-title ml-2"
            @on:button-click="unLinkSession(session)"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col align="middle">
        <d-button
          text="general.actions.dismiss"
          class="d-btn-sm d-btn btn d-btn-danger font-text-title"
          @on:button-click="closeModal"
        />
      </b-col>
    </b-row>
    <cash-in-hand-modal
      :display="displayRegistryModal"
    />
  </b-modal>
</template>
<script>

import CashInHandModal from "@custom/caisse/registry/CashInHandModal";

export default {
  components: { CashInHandModal },
  data: () => ({ displayRegistryModal: false }),
  computed: {
    display () {
      return this.$store.getters["cashRegistry/getDisplayCashRegistriesModal"];
    },
    storedSession () {
      return this.$store.getters["cashRegistry/getSession"];
    },
    sessions () {
      return this.$store.getters["cashRegistry/getSessions"];
    },
    register () {
      return this.$store.getters["cashRegistry/getRegister"];
    },
  },
  methods: {
    closeModal () {
      this.$store.commit("cashRegistry/setDisplayCashRegistriesModal", false);
    },
    onSelect (session) {
      this.$store.commit("cashRegistry/setSession", session);

      this.displayRegistryModal = ! this.displayRegistryModal;
    },
    sessionSelection (id) {
      if (this.storedSession) {
        return this.storedSession.id === id ? "selected" : "not-selected";
      }

      return "not-selected";
    },
    unLinkSession (session) {
      this.$store.commit("cashRegistry/initSession", session);
    },
    sessionIcon (id) {
      return require(`@/assets/cash-registry/${this.sessionSelection(id)}-caisse.svg`);
    },
    checkRegister () {
      if (null !== this.register.id) {
        this.$store.commit("cashRegistry/checkRegisterValidity");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@lazy/_modal.scss";
@import "@lazy/_b-card.scss";

.description {
  text-align: left;
  font: normal normal normal 14px/21px Avenir;
  letter-spacing: 0.2px;
  color: #565555;
  opacity: 1;
}

/deep/ .modal .modal-body .card {
  box-shadow: #0b1620 !important;
}

.cash-card {
  .card-body {
    display: flex;
    flex-direction: column;

    .cash-label {
      text-align: center;
      text-transform: uppercase;
      font: normal normal bold 14px Avenir;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &[data-type = "not-selected"] {
        color: #FF0101;
      }

      &[data-type = "selected"] {
        color: #6EC45F;
      }
    }
  }

  img {
    width: 140px;
    height: 180px;
    display: flex;
    justify-content: center;
    margin: auto;
  }
}

</style>