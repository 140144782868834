export default {
  set: (context, payload) => {
    context.commit("set", payload);
  },
  check: (context, payload) => {
    context.commit("check", payload);
  },
  clear: (context, payload) => {
    context.commit("clear", payload);
  },
};