// Définition des constantes de couleurs pour une jauge de remplissage en pourcentage
const COLORS = {
  COLOR_0_PERCENT: "hsla(225 65% 50% / .8)", // Bleu clair
  COLOR_25_PERCENT: "hsla(120 50% 50% / .8)", // Vert pâle
  COLOR_50_PERCENT: "hsla(60 67% 55% / .8)", // Jaune pâle,
  COLOR_75_PERCENT: "hsla(35 70% 55% / .8)", // Orange pâle,
  COLOR_90_PERCENT: "hsla(25 75% 50% / .8)", // Rouge pâle,
  COLOR_100_PERCENT: "hsla(0 75% 50% / .8)", // Rouge vif,
};

export { COLORS };