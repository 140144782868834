<template>
  <section v-if="showActualities && actualities.length" class="activities-wrapper">
    <ssr-carousel :autoplay-delay="10" :slides-per-page="1" show-dots>
      <div v-for="(actuality, i) in actualities" :key="actuality.id" class="slide">
        <div class="slide__content">
          <div class="slide__content--left">
            <h3 class="slide__content__title">
              {{ actuality.title }}
              <span class="slide__content__title__date">{{ formatDate(actuality.displayStartDate) }}</span>
            </h3>
            <p>{{ actuality.content }}</p>
          </div>
          <div v-if="actuality.ctaLabel && actuality.ctaLabel !== ''" class="slide__content--right">
            <button class="button" @click="selectActuality(i)">
              {{ actuality.ctaLabel }}
            </button>
          </div>
        </div>
      </div>
    </ssr-carousel>
    <div class="close-btn" @click="hideActualities()">
      <i class="fa fa-close close" />
    </div>
    <activity-modal :display="showModalActuality" :data="selectedActuality" @close="showModalActuality = false" />
  </section>
</template>

<script>
import { getDoinsportActualities } from "@api/doinsport/services/actualities/doinsportActualities.api";
import ActivityModal from "@custom/actuality/ActualityModal.vue";
import SsrCarousel from "vue-ssr-carousel";
import ssrCarouselCss from "vue-ssr-carousel/index.css";

export default {
  components: {
    SsrCarousel,
    ssrCarouselCss,
    ActivityModal,
  },
  data () {
    return {
      actualities: [],
      selectedActuality: {},
      showActualities: true,
      showModalActuality: false,
    };
  },
  mounted () {
    const hideTime = localStorage.getItem("actualitiesHideTime");
    if (hideTime) {
      const hideDate = new Date(Number(hideTime));
      const followingDayStart = this.getFollowingDayStart(hideDate);
      if (new Date() < followingDayStart) {
        this.showActualities = false;
      } else {
        localStorage.removeItem("actualitiesHideTime");
      }
    }

    if (this.showActualities) {
      this.fetchActualities();
    }
  },
  methods: {
    async fetchActualities () {
      const actualities = await getDoinsportActualities();
      this.actualities = actualities.data._embedded?.item ?? [];
    },
    selectActuality (index) {
      this.selectedActuality = this.actualities[index];
      if(this.selectedActuality.ctaLink && this.selectedActuality.ctaLink !== "") {
        window.open(this.selectedActuality.ctaLink, "_blank");
      } else {
        this.showModalActuality = true;
      }
    },
    hideActualities () {
      localStorage.setItem("actualitiesHideTime", Date.now().toString());
      this.showActualities = false;
    },
    getFollowingDayStart (date = new Date()) {
      date.setDate(date.getDate() + 1);
      date.setHours(0, 0, 0, 0);
      return date;
    },
    formatDate (date) {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const locale = navigator.language || navigator.userLanguage;
      return new Date(date).toLocaleDateString(locale, options);
    },
  },
};
</script>

<style lang="scss" scoped>
/**
 * Overrides SSR Carousel
 */
/deep/ .disabled { // Permet d'annuler le style par défaut de l'application pour le .disabled
  cursor: default !important;
  opacity: 1 !important;
}
/deep/ [aria-disabled] > .ssr-carousel-dot-icon {
  background: #fff;
}
/deep/ .ssr-carousel-dot-icon {
  border: 2px solid #fff;
}

$color-red: #dc3545;
$color-blue: #0B2772;

.activities-wrapper {
  position: relative;
  background: $color-blue;
  min-height: 80px;
  color: #fff;
  width: calc(100% + 30px);
  margin-left: -10px;
  padding: 2vh 3vw;
  padding-right: 50px;

  .slide {
    width: 100%;

    &__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;

      &--left {
        width: 75%;
      }

      &__title {
        text-transform: uppercase;
        &__date {
          font-size: 16px;
          font-weight: 600;
          color: $color-red;
          margin-left: 10px;
        }
      }
    }
  }

  .button {
    height: 38px;
    padding: 0 16px;
    background: $color-red;
    color: #fff;
    font-size: 16px;
    border: 0;
    border-radius: 3px;
    text-align: center;
  }

  .close-btn {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 7px;

    i {
      font-size: 16px;
      color: #fff;
      opacity: 1;
    }
  }
}
</style>