import axios from "axios";
import { _post, _put } from "@api/doinsport/services/httpService";

export const userExist = (email) => _post("/user-exists", {
  identifier: email,
  userType: "user_club",
});

export const requestValidationIntents = (email) => _post("/validation-intents.json", {
  email,
  userType: "user_club",
});

export const processValidationCheck = (email, validationCode) => _post("/validation-check", {
  email,
  validationCode,
  userType: "user_club",
});

export const editUserClientsPassword = ((id, password, token) => {
  return _put(`/user-clubs/${id}`, {
    password,
    plainPassword: password,
  }, { headers: { "X-Confirmation-Token": token } });
});