const WIDTH = "30";
const HEIGHT = "30";
const VIEW_BOX = "0 0 28.815 28.815";

export const RED_COLOR = "#FF0101";
export const GREY_COLOR = "#bbc5d5";

export const SVG_CONFIG = {
  width: WIDTH,
  height: HEIGHT,
  color: GREY_COLOR,
  viewBox: VIEW_BOX,
};

export const SIDEBAR_COMPONENTS = {
  DropMenuSidebar: () => import(/* webpackChunkName: "Planning" */"@/components/core/dropmenu-sidebar"),
  Dashboard: () => import(/* webpackChunkName: "Planning" */"@/components/core/sidebar/icons/Dashboard"),
  News: () => import(/* webpackChunkName: "Planning" */"@/components/core/sidebar/icons/News"),
  Lesson: () => import(/* webpackChunkName: "Planning" */"@/components/core/sidebar/icons/Lesson"),
  Access: () => import(/* webpackChunkName: "Planning" */"@/components/core/sidebar/icons/Access"),
  Booking: () => import(/* webpackChunkName: "Planning" */"@/components/core/sidebar/icons/Booking"),
  Client: () => import(/* webpackChunkName: "Planning" */"@/components/core/sidebar/icons/Client"),
  Finance: () => import(/* webpackChunkName: "Planning" */"@/components/core/sidebar/icons/Finance"),
  Marketing: () => import(/* webpackChunkName: "Planning" */"@/components/core/sidebar/icons/Marketing"),
  Planning: () => import(/* webpackChunkName: "Planning" */"@/components/core/sidebar/icons/Planning"),
  Match: () => import(/* webpackChunkName: "Planning" */"@/components/core/sidebar/icons/Match"),
  Offer: () => import(/* webpackChunkName: "Planning" */"@/components/core/sidebar/icons/Offer"),
  Ticket: () => import(/* webpackChunkName: "Planning" */"@/components/core/sidebar/icons/Ticket"),
  Shop: () => import(/* webpackChunkName: "Planning" */"@/components/core/sidebar/icons/Shop"),
  Playground: () => import(/* webpackChunkName: "Planning" */"@/components/core/sidebar/icons/Playground"),
  CashRegistry: () => import(/* webpackChunkName: "Planning" */"@/components/core/sidebar/icons/CashRegistry"),
};