import { _get, _put, clubId } from "@api/doinsport/services/httpService";

export const CLUB_URL = "clubs/";

export const getCurrentClub = () => _get(CLUB_URL + clubId);

export const getClubByIri = (iri) => _get(iri);

export const putClub = (id, club) => _put(CLUB_URL + clubId, club);

export const getParams = (dateString) => _get(CLUB_URL + clubId + "/planning-parameter?date=" + dateString + "&partial=true");

export const getOrderConfiguration = (id) => _get(CLUB_URL + `${id}/order-configuration`);

export const putOrderConfiguration = (id, data) => _put(CLUB_URL + `order-configurations/${id}`, data);