<template>
  <div class="container-fluid">
    <div>
      <b-row>
        <b-col align="middle">
          <label class="settings-title ml-4">
            {{ $t('caisse.register.details') }} {{ session.username }}
          </label>
        </b-col>
      </b-row>
      <div class="border-bottom-grey" />
    </div>
    <div
      class="p-4 custom-sidebar-height"
    >
      <div class="d-flex">
        <label class="simple-label-avenir-blue">{{ $t('caisse.register.status') }} : </label>
        <label class="ml-2">{{ $t('caisse.register.' + register.status) }}</label>
      </div>
      <div class="d-flex">
        <label class="simple-label-avenir-blue">{{ $t('caisse.title') }} : </label>
        <label class="ml-2"> {{ session.username }} </label>
      </div>
      <div class="d-flex">
        <label class="simple-label-avenir-blue">{{ $t('caisse.register.cash') }} : </label>
        <label class="ml-2"> {{ register.cash_in_hand }} {{ $currency }}</label>
      </div>
      <div class="d-flex">
        <label class="simple-label-avenir-blue">{{ $t('caisse.register.opened-at') }} : </label>
        <label class="ml-2"> {{ $toTimezoneCustomFormat({date: register.date_utc, format: 'DD/MM/YYYY HH:mm:ss'}) }} </label>
      </div>
      <div class="d-flex">
        <label class="simple-label-avenir-blue">{{ $t('caisse.register.closed-at') }} : </label>
        <label class="ml-2"> {{ $toTimezoneCustomFormat({date: register.closed_at_utc, format: 'DD/MM/YYYY HH:mm:ss'}) ?? '-' }} </label>
      </div>
    </div>
    <div class="footer-bottom pl-4 pr-4">
      <div class="border-bottom-grey-dark mb-3" />
      <b-row>
        <b-col align="middle">
          <d-button
            text="general.actions.dismiss"
            class="d-btn-sm font-text-title d-btn btn d-btn-danger font-text-title mt-2"
            @on:button-click="$emit('hide')"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>

import { getRegister } from "@api/nextore/services/registry.api";

export default {
  computed: {
    session () {
      return this.$store.getters["cashRegistry/getSession"];
    },
    register () {
      return this.$store.getters["cashRegistry/getRegister"];
    },
  },
  created () {
    this.loadRegister();
  },
  methods: {
    loadRegister () {
      if (this.register) {
        getRegister(this.register.id)
          .then((response) => {
            this.$store.commit("cashRegistry/switchRegister", response.data[0]);
          })
        ;
      }
    },
  },
};
</script>