import axios from "axios";

export const VUE_APP_NEXTORE_URL = "https://caisse.doinsport.club/";

export const VUE_APP_NEXTORE_API_URL = `${VUE_APP_NEXTORE_URL}/api`;

const currentClub = JSON.parse(localStorage.getItem("current-club"));

const httpNextore = axios.create({
  baseURL: VUE_APP_NEXTORE_API_URL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    "X-API-KEY": currentClub ?
      (
        currentClub.nextoreAccount ?
          currentClub.nextoreAccount.apiToken :
          ""
      ) :
      "",
  },
});

export default httpNextore;