import accessControl from "@/router/access-control";
import articles from "@/router/articles";
import auth from "@/router/auth";
import bookings from "@/router/bookings";
import clients from "@/router/clients";
import credits from "@/router/credits";
import creditsPacks from "@/router/credits-packs";
import dashboard from "@/router/dashboard";
import events from "@/router/events";
import lessons from "@/router/lessons";
import liveAccesses from "@/router/live-accesses";
import marketing from "@/router/marketing";
import planning from "@/router/planning";
import playground from "@/router/playground";
import reports from "@/router/reports";
import sales from "@/router/sales";
import settings from "@/router/settings";
import shop from "@/router/shop";
import subscriptions from "@/router/subscriptions";
import users from "@/router/users";
import walletRefills from "@/router/wallet-refills";
import webhooks from "@/router/webhooks";
import { isNotUndefinedAndNotNull } from "@/utils/classes";
import store from "@plugins/store";
import TheBody from "../components/core/TheBody";

const permissionsQuery = localStorage.getItem("userPermissions");

const club = store.getters["auth/currentClub"];
const userPermissions = permissionsQuery !== "undefined" && isNotUndefinedAndNotNull(permissionsQuery) ? JSON.parse(permissionsQuery) : [];
const homePage = userPermissions.find(el => el === "CLUB_PLANNING_READ");
const customerPage = userPermissions.find(el => el === "CLUB_CLIENT_READ");

const homeRoute = userPermissions.length === 0 ? { name: "planning" } : isNotUndefinedAndNotNull(homePage) ? { name: "planning" } : club.nextoreAccount ? { name: "caisse_index" } : isNotUndefinedAndNotNull(customerPage) ? { name: "users" } : { name: "settings" };

const routes = [
  {
    path: "",
    redirect: homeRoute,
  },
  {
    path: "/",
    component: TheBody,
    meta: { requiresAuth: true },
    children: [
      dashboard,
      planning,
      settings,
      ...users,
      ...lessons,
      ...shop,
      ...sales,
      ...liveAccesses,
      ...accessControl,
      ...webhooks,
      ...walletRefills,
      ...articles,
      ...events,
      ...marketing,
      ...subscriptions,
      ...playground,
      ...credits,
      ...creditsPacks,
      ...clients,
      ...bookings,
      ...reports,
    ],
  },
  auth,
];

export default routes;