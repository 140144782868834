<template>
  <p class="occupancy-badge" :class="{ 'small': small }" :style="{ 'background-color': bgColor }">
    <span v-if="! small">{{ $t("views.reports.fill.title") }}</span>
    <span>
      {{ loading ? "-" : value }} %
    </span>
  </p>
</template>

<script>
import { COLORS } from "@/constants/occupancy/colors.js";

export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    bgColor () {
      if (this.value <= 25) {
        return COLORS.COLOR_0_PERCENT;
      } else if (this.value <= 50) {
        return COLORS.COLOR_25_PERCENT;
      } else if (this.value <= 75) {
        return COLORS.COLOR_50_PERCENT;
      } else if (this.value <= 90) {
        return COLORS.COLOR_90_PERCENT;
      }
      return COLORS.COLOR_100_PERCENT;

    },
  },
};
</script>

<style scoped lang="scss">
.occupancy-badge {
  display: inline-block;
  padding: 0 .8vw;
  margin-bottom: 0;
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  font-weight: 700;
  transition: background-color 0.3s ease-out;

  &.small {
    padding: 0 .5vw;
    font-size: .8rem;
    font-weight: 600;
  }
}
</style>