import { cloneData } from "@/utils/form";

export default {
  setStatsParams: (state, payload) => {
    let twoRows = false;
    let threeRows = false;
    let nbRows = 1;

    const clonePayload = cloneData(payload);

    clonePayload.sort((el1, el2) => el1.row - el2.row);

    let indexMin = 999;

    for (let i = 0; i < clonePayload.length; i ++) {
      const param = clonePayload[i];

      if (param.position < indexMin && param.row === 1) {
        indexMin = param.position;
      }

      if (param.row === 3) {
        threeRows = true;
        break;
      }
      if (param.row === 2) {
        twoRows = true;
      }
    }

    if (threeRows) {
      nbRows = 3;
    }

    if (! threeRows && twoRows) {
      nbRows = 2;
    }

    state.stats.rows = [];

    for (let i = 0; i < nbRows; i ++) {
      state.stats.rows.push([]);
    }

    for (let i = 0; i < payload.length; i ++) {
      const param = payload[i];

      param.collapse = param.position === indexMin;

      state.stats.rows[param.row - 1].push(param);
    }
  },
  setStatsIsDraggable: (state, payload) => {
    state.stats.isDraggable = payload;
  },
  updateDuplicableChart: (state, payload) => {
    state.stats.isDuplicable = payload;
  },
  updateEnabledChart: (state, payload) => {
    for (const charts of state.stats.rows) {
      for (let index = 0; index < charts.length; index ++) {
        if (charts[index]["@id"] === payload["@id"]) {
          charts[index].enabled = payload.enabled;
          break;
        }
      }
    }
  },
  updateDownloadableChart: (state) => {
    state.stats.isDownloadable = ! state.stats.isDownloadable;
  },
  updateChartActivities: (state, payload) => {
    for (const charts of state.stats.rows) {
      for (const chart of charts) {
        if (chart["@id"] === payload.chart["@id"]) {
          chart.activityIds = [payload.activity];
        }
      }
    }
  },
};