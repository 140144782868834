<template>
  <b-modal
    ref="actuality-modal"
    :title="data.title"
    hide-footer
    body-bg-variant="gray-lighten"
    @hidden="close()"
  >
    <div v-html="data.ctaContent" />
  </b-modal>
</template>

<script>
export default {
  props: {
    display: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  watch: {
    display: function () {
      if (this.display) {
        this.$refs["actuality-modal"].show();
      } else {
        this.$refs["actuality-modal"].hide();
      }
    },
  },
  mounted () {
    this.$refs["actuality-modal"].$on("bv::modal::hide", () => {
      this.close();
    });
  },
  methods: {
    close () {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@lazy/_modal.scss";
</style>