<template>
  <ul
    id="sidebar-id"
    :class="$store.getters['layout/getInnerWidth'] < 761 ? isOpen === true ? '': 'd-none' : ''"
    class="sidebar-menu list-menu sidebar-shadow "
  >
    <li
      v-for="(menuItem, index) in menuItems"
      v-if="hasEnzoCardAccess(menuItem)"
      id="li-side-bar"
      :key="index"
      :class="isActiveMenu(menuItem) ? 'active-li': ''"
      class="li-side-menu li-side-mobile"
      @mouseover="menuItem.size= 30"
      @mouseleave="menuItem.size = 27;"
    >
      <drop-menu-sidebar
        v-if="menuItem.subMenu && menuItem.options.length > 0"
        :key="'sideBar' + index"
        :title="$t(menuItem.tooltip)"
        :options="menuItem.options"
        @on:hide="hideAllSubMenus"
        @on:hide-mobile="hideMobileMenu"
      />
      <a
        v-if="menuItem.type === 'link'"
        class="sidebar-header p-2 pointer"
        @click="show(menuItem)"
      >
        <Component
          :is="menuItem.icon.name"
          :svg="menuItem.icon"
          :color="getIconColor(menuItem)"
        />
        <i
          v-if="menuItem.children"
          class="fa fa-angle-right pull-right"
        />
        <span
          class="icon-sub-label mb-1 mt-1"
          :class="(!isActiveMenu(menuItem) && menuItem.size === 27 ) ? 'inactive-menu-text': 'active-menu-text'"
        >
          {{ $t(menuItem.tooltip) }}
        </span>
      </a>
    </li>
  </ul>
</template>
<script>
import { mapState } from "vuex";
import * as sideBarModule from "@/constants/sidebar/sidebar.config";
import { GREY_COLOR, RED_COLOR } from "@/constants/sidebar/sidebar.config";
import { getUserAccesses } from "@api/doinsport/services/user-club/user-club.api";
import { isNotUndefinedAndNotNull } from "@/utils/classes";

export default {
  components: sideBarModule.SIDEBAR_COMPONENTS,
  props: {
    toggleSidebar: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      width: 0,
      componentKey: 0,
      height: 0,
      equalPath: "/planning",
      margin: 0,
      hideRightArrowRTL: true,
      hideLeftArrowRTL: true,
      hideRightArrow: true,
      hideLeftArrow: true,
      menuWidth: 0,
      menuOptions: [],
      isOpen: false,
    };
  },
  watch: {
    toggleSidebar: function () {
      this.isOpen = ! this.isOpen;
      this.$emit("on:send-data", this.isOpen);
    },
  },
  computed: {
    ...mapState({
      menuItems: state => state.sidebar.menus,
      layout: state => state.layout.layout,
      permissions: state => state.userPermissions.permissions,
      sidebar: state => state.layout.sidebarType,
    }),
    hasEnzoCardConfig () {
      return this.$store.getters["auth/currentClub"].accessControlService;
    },
    innerWidth () {
      return this.$store.getters["layout/getInnerWidth"];
    },
  },
  created () {
    window.addEventListener("resize", this.handleResize);
    this.initActiveMenu();

    this.handleResize();

    if (this.width < 991) {
      this.layout.settings.sidebar.type = "default";
    }

    const val = this.sidebar;
    if (val === "default") {
      this.layout.settings.sidebar.type = "default";
      this.layout.settings.sidebar.body_type = "default";
    } else if (val === "compact") {
      this.layout.settings.sidebar.type = "compact-wrapper";
      this.layout.settings.sidebar.body_type = "sidebar-icon";
    } else if (val === "compact-icon") {
      this.layout.settings.sidebar.type = "compact-page";
      this.layout.settings.sidebar.body_type = "sidebar-default";
    } else if (val === "horizontal") {
      this.layout.settings.sidebar.type = "horizontal_sidebar";
      this.layout.settings.sidebar.body_type = "";
    }
  },
  mounted () {
    this.$bus.$on("refresh-sidebar", () => {
      this.componentKey ++;
    });
  },
  destroyed () {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    getIconColor (menuItem) {
      return (! this.isActiveMenu(menuItem) && menuItem.size === 27) ? GREY_COLOR : RED_COLOR;
    },
    hasEnzoCardAccess (menuItem) {
      if (menuItem.title === "views.live-accesses.index.menu") {
        return this.hasEnzoCardConfig && this.hasPermission(menuItem);
      }
      return this.hasPermission(menuItem);
    },
    hasPermission (menuItem) {
      if (menuItem.permission) {
        const permission = this.permissions.find(el => el === menuItem.permission);

        return isNotUndefinedAndNotNull(permission);
      }

      return true;
    },
    sendData () {
      this.$emit("on:send-data", this.isOpen);
    },
    hideMobileMenu () {
      this.isOpen = false;
    },
    hideAllSubMenus () {
      for (const menu of this.menuItems) {
        menu.subMenu = false;
      }
      this.isOpen = false;
      this.sendData();
    },
    isActiveMenu (menuItem) {
      if (this.$router.currentRoute.path.includes(menuItem.path) && menuItem.active) {
        return true;
      } else if (menuItem.options.length > 0 && menuItem.active) {
        return true;
      }
      return false;
    },
    setNavActive (item) {
      this.$store.dispatch("menu/setNavActive", item);
    },
    handleResize () {
      this.width = window.innerWidth - 310;
    },
    initActiveMenu () {
      for (const menu of this.menuItems) {
        menu.active = (menu.path === this.$router.currentRoute.path);
      }
    },
    show (menuItem) {
      for (const menu of this.menuItems) {
        if (menu.path === menuItem.path) {
          menu.active = true;
          menu.subMenu = true;
          menu.size = 30;
        } else {
          menu.active = false;
          menu.subMenu = false;
          menu.size = 27;
        }
      }
      if (menuItem.options.length === 0) {
        if (this.$router.currentRoute.path !== menuItem.path) {
          this.isOpen = false;
          this.$router.push(menuItem.path);
          this.hideAllSubMenus();
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@lazy/menu/_sidebar-menu.scss";

.inherited-display {
  display: inherit;
}

.icon-sub-label {
  color: #707070;
  text-decoration: none;
  font-size: 14px;
  font-family: Avenir;
}

.a:hover {
  color: red;
}

.sidebar-header:hover {
  color: red;
}

.inactive-menu-text {
  color: #707070;
}

.active-menu-text {
  color: red;
}

.sidebar-shadow {
  height: calc(100vh + 1000px);
  margin-top: 63px;
  background-color: #FFFFFF;
  box-shadow: 0 10px 20px rgba(198, 198, 202, 0.4);
}

.d-content-height {
  display: none;
}

.hamburger-logo {
  display: flex;
  padding: 15px 15px 28px 28px;
  font-size: 25px;
  color: #FF0101;
}

</style>