import axios from "axios";
import Bus from "@/utils/bus";
import httpClient from "@api/doinsport/httpClient";
import { getCurrentClub } from "@api/doinsport/services/club/club.api";
import { isNotUndefinedAndNotNull } from "@/utils/classes";

const urlAPI = process.env.VUE_APP_BASE_URL;

export default {
  login ({ commit, dispatch, getters }, user) {
    return new Promise((resolve, reject) => {
      commit("authRequest");
      axios({
        url: urlAPI + "club_login_check",
        data: user,
        method: "POST",
      })
        .then(resp => {
          const refreshToken = resp.data.refresh_token;
          const token = resp.data.token;
          localStorage.setItem("refresh_token", JSON.stringify(refreshToken));
          localStorage.setItem("token", JSON.stringify(token));
          axios.defaults.headers.common["Authorization"] = "Bearer " + token;
          httpClient.defaults.headers.Authorization = "Bearer " + resp.data.token;
          commit("authSuccess", token);
          dispatch("getMe").then(() => {
            dispatch("getSelectedClub").then(() => {
              const cacheClub = JSON.parse(localStorage.getItem("default-club"));
              if (isNotUndefinedAndNotNull(cacheClub) && cacheClub.user && cacheClub.club) {
                const userClub = getters["currentUser"];
                if (cacheClub.user.id === userClub.id) {
                  if (cacheClub.club.id !== getters["currentClub"].id) {
                    dispatch("switchClub", cacheClub.club);
                  }
                }
              } else {
                localStorage.setItem("default-club", JSON.stringify({
                  club: getters["auth/currentClub"],
                  user: getters["auth/currentUser"],
                }));
              }

              resolve(resp);
            });
          });
        })
        .catch(err => {
          commit("authError");
          reject(err);
        });
    });
  },
  getMe ({ commit }) {
    return new Promise((resolve, reject) => {
      axios({
        url: urlAPI + "me",
        method: "GET",
      })
        .then(resp => {
          const user = resp.data;
          localStorage.setItem("user", JSON.stringify(user));
          localStorage.setItem("selectedClub", JSON.stringify(user.clubs[0]["@id"]));
          Bus.$emit("on:user-connected", user.clubs[0]["@id"]);
          commit("authMe", user);
          resolve(resp);
        })
        .catch(err => {
          commit("authError", err);
          reject(err);
        });
    });
  },
  getSelectedClub ({ commit }) {
    return new Promise((resolve, reject) => {
      getCurrentClub()
        .then(resp => {
          localStorage.setItem("current-club", JSON.stringify(resp.data));
          if (resp.data.accessControlService) {
            localStorage.setItem("enzocard-token", JSON.stringify(resp.data.accessControlService.apiAuthenticationToken));
          } else {
            localStorage.setItem("enzocard-token", null);
          }

          Bus.$emit("on:current-club-loaded", resp.data);
          commit("selectedClub", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("authError", err);
          reject(err);
        });
    });
  },
  switchClub: (context, payload) => {
    localStorage.setItem("current-club", JSON.stringify(payload));
    Bus.$emit("on:current-club-loaded", payload);
    if (payload.accessControlService) {
      localStorage.setItem("enzocard-token", JSON.stringify(payload.accessControlService.apiAuthenticationToken));
    } else {
      localStorage.setItem("enzocard-token", null);
    }
    localStorage.setItem("selectedClub", JSON.stringify(payload["@id"]));
    Bus.$emit("on:user-connected", payload["@id"]);
    context.commit("selectedClub", payload);
  },
  logout ({ commit }) {
    return new Promise((resolve, reject) => {
      commit("logout");
      resolve();
    });
  },
};