import { isProdEnv } from "@/utils/env";

export const appendChatBoxScript = () => {
  const externalScript = document.createElement("script");
  externalScript.setAttribute("src", "//js.hs-scripts.com/5668306.js");
  externalScript.setAttribute("id", "hs-script-loader");

  if (isProdEnv()) {
    document.head.appendChild(externalScript);
  }
};