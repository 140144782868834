export default {
  setBlockPriceVariations: (state, payload) => {
    state.blockPriceVariations = payload;
  },
  removeBlockPriceVariation: (state, payload) => {
    const findInVariations = state.blockPriceVariations.find(el => el.id === payload.id);

    if ("undefined" !== typeof findInVariations) {
      state.blockPriceVariations.splice(state.blockPriceVariations.indexOf(findInVariations), 1);
    }
  },
  addBlockPriceVariation: (state, payload) => {
    state.blockPriceVariations.push(payload);
  },
};