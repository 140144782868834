import App from "@/App.vue";
import * as Sentry from "@sentry/vue";
import Vue from "vue";

Vue.config.productionTip = false;
Vue.config.silent = true;

let appVue;

const loadAppVue = () => {
  let plugins;

  const promises = [
    import("@/plugins").then((obj) => {
      plugins = obj;
    }),
    import("@/filters"),
    import("@/directives"),
  ];

  Promise.allSettled(promises).then(() => {
    if (process.env.VUE_APP_SENTRY_DSN) {
      Sentry.init({
        Vue,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration(),
        ],
        tracesSampleRate: 0.1,
        tracePropagationTargets: [/^https:\/\/v3-develop-api.doinsport\.club/],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
      });
    }

    appVue = new Vue({
      ...plugins.default,
      render: h => h(App),
    });
    appVue.$mount("#app");
  });
};

loadAppVue();