const path = "subscriptions/credits-packs";

export default [
  {
    path: path,
    name: "credits_packs_list",
    component: () => import("@views/credits-pack/Index"),
  },
  {
    path: path + "/new",
    name: "credits_pack_new",
    component: () => import("@views/subscriptions/credit-pack-list/credit-pack-create-update/Index"),
  },
  {
    path: path + "/" + "update" + "/:id",
    name: "credits_pack_update",
    component: () => import("@views/subscriptions/credit-pack-list/credit-pack-create-update/Index"),
  },
];