const path = "clients";

export default [
  {
    path: path,
    name: "clients_list",
    component: () => import("@views/client/Index"),
    meta: { title: "data.menu.clients.meta.title" },
  },
  {
    path: path + "/" + "details" + "/:id",
    name: "customer_details",
    component: () => import("@views/client/details/Index"),
  },
  {
    path: path + "/" + "details" + "/:id" + "/subscription-new",
    name: "customer_subscription_new",
    component: () => import("@views/client/details/body/left/add-subscription/Index"),
  },
  {
    path: path + "/" + "details" + "/:id" + "/credits-pack-new",
    name: "customer_credits-pack_new",
    component: () => import("@views/client/details/body/left/add-credits-pack/Index"),
  },
  {
    path: path + "/" + "details" + "/:id" + "/wallet-refill-new",
    name: "customer_wallet-refill_new",
    component: () => import("@views/client/details/body/left/add-wallet/Index"),
  },
  {
    path: path + "/" + "new",
    name: "customer_new",
    component: () => import("@views/client/new/Index"),
  },
  {
    path: path + "/" + "import",
    name: "customer_import",
    component: () => import("@views/client/import/Index"),
  },
];