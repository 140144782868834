<template>
  <d-dropdown-form
    :key="'d-drop-down' + dropDownKey"
    :hide="hide"
    :show="show"
    :right="true"
    min-width="11em"
    identifier="nextore-devices"
    padding="0"
    padding-item="0.66rem 1.6rem"
    item-hover="#EDEFF2"
    background-color="#FFFFFF"
    @on:display:change="onChange"
  >
    <template #dropdownbutton>
      <img
        :src="sessionIcon"
        class="pointer ml-2 mr-2"
        width="20px"
        @on:button-click="show=!show"
      >
    </template>
    <template #dropdownbody>
      <div style="cursor: unset" class="padding-params">
        <h6
          class="settings-title ml-4 mt-2 mb-2"
        >
          {{ $t('caisse.title') }}
        </h6>
      </div>
      <div class="border-bottom" />
      <div
        class="dropdown-item simple-label-avenir pointer"
        @click="goToCaisse"
      >
        {{ $t('caisse.caisse-link') }}
      </div>
    </template>
  </d-dropdown-form>
</template>
<script>

import CashRegistries from "@custom/caisse/CashRegistries";
import { getCashRegistries } from "@api/nextore/services/users.api";
import ExpiredRegisterModal from "@custom/caisse/registry/ExpiredRegisterModal";

export default {
  props: {
    duration: {
      type: Number,
      default: () => 0,
    },
  },
  data: () => ({
    displayCashRegistry: false,
    show: false,
    hide: false,
    dropDownKey: 0,
  }),
  computed: {
    session () {
      return this.$store.getters["cashRegistry/getSession"];
    },
    sessionSelection () {
      return this.session.id ? "selected" : "not-selected";
    },
    sessionIcon () {
      return require(`@/assets/cash-registry/${this.sessionSelection}-caisse.svg`);
    },
  },
  methods: {
    displayModal () {
      this.$store.commit("cashRegistry/setDisplayCashRegistriesModal", true);
    },
    onChange (display) {
      this.$bus.$emit("on:drop-menu:display", display);
    },
    goToCaisse () {
      window.open("https://caisse.doinsport.club/pos", "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .dropdown-menu {
  min-width: 5rem;
  top: 30px;
}

/deep/ .dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #919194;
}

/deep/ .icons-size-cog {
  font-size: 20px;
}

</style>