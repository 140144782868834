import { cloneData, randomString } from "@/utils/form";

export default {
  addProduct: (state, payload) => {
    if (state.tapeValue !== 0 && state.tapeValue !== null) {
      payload.nbArticle = state.tapeValue;
    } else {
      payload.nbArticle = 1;
    }

    state.sale.products.push(payload);
    state.showModal = true;
  },
  updateProduct: (state, payload) => {
    const productSale = state.sale.products.find(el => el.id === payload.id);

    if (state.tapeValue !== 0 && state.tapeValue !== null) {
      productSale.nbArticle += state.tapeValue;
    } else {
      productSale.nbArticle ++;
    }

    productSale.quantity ++;
  },
  addPayment: (state, payload) => {
    let amount = 0;
    state.sale.products.forEach(product => {
      amount = amount + (product.price * product.quantity);
    });
    state.sale.payments.forEach(payment => {
      amount = amount - payment.value;
    });

    if (parseFloat(state.tapeValue) > 0 && state.tapeValue >= amount) {
      payload.value = parseFloat(amount).toFixed(2);
      state.rendu = state.tapeValue - amount;
    } else {
      if (parseFloat(state.tapeValue) > 0) {
        payload.value = parseFloat(state.tapeValue).toFixed(2);
      } else {
        payload.value = parseFloat(amount).toFixed(2);
      }
      state.rendu = 0;
    }

    state.sale.payments.push(payload);
    state.tapeValue = 0;
  },
  setMethod: (state, payload) => {
    state.method = payload;
  },
  setBooking: (state, payload) => {
    state.booking = payload;
  },
  setCustomer: (state, payload) => {
    state.customer = payload;
  },
  setShowModal: (state, payload) => {
    state.showModal = payload;
  },
  removeProduct: (state, payload) => {
    state.sale.products.splice(payload, 1);
  },
  removeProducts: (state) => {
    state.sale.products = [];
  },
  setNextoreProducts: (state, payload) => {
    state.nextoreProducts = payload;
  },
  clearSale: (state, payload) => {
    state.sale.products = [];
    state.sale.payments = [];
    state.customer = null;
    state.booking = null;
    state.showModal = false;
  },
  updatePrice: (state, payload) => {
    if (state.sale.products[payload]) {
      state.sale.products[payload].price = parseFloat(state.tapeValue).toFixed(2);
    }
    state.tapeValue = 0;
  },
  updateQuantity: (state, payload) => {
    if (state.sale.products[payload]) {
      state.sale.products[payload].quantity = parseFloat(state.tapeValue).toFixed(2);
    }
    state.tapeValue = 0;
  },
  setTapeValue: (state, payload) => {
    state.tapeValue = payload;
  },
  setRendu: (state, payload) => {
    state.rendu = payload;
  },
  saveState: (state) => {
    const clonedState = cloneData(state);

    clonedState.id = randomString();
    clonedState.creationDate = new Date();

    state.tickets.push(clonedState);

    resetState(state);
  },
  cancelLastTicket: (state) => {
    state.tickets.splice(state.tickets.length - 1, 1);
  },
  restoreState: (state, payload) => {
    state.sale = payload.ticket.sale;

    state.method = payload.ticket.method;
    state.tapeValue = payload.ticket.tapeValue;
    state.rendu = payload.ticket.rendu;
    state.customer = payload.ticket.customer;
    state.booking = payload.ticket.booking;
    state.creationDate = payload.ticket.creationDate;
    state.showModal = payload.ticket.showModal;

    const findTicket = state.tickets.find(el => el.id === payload.id);
    state.tickets.splice(state.tickets.indexOf(findTicket), 1);
  },
};

const resetState = (state) => {
  state.sale = {
    products: [],
    payments: [],
  };

  state.method = null;
  state.tapeValue = 0;
  state.rendu = 0;
  state.customer = null;
  state.creationDate = null;
  state.booking = null;
  state.showModal = false;
};