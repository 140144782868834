const path = "bookings";

export default [
  {
    path: path,
    name: "bookings",
    component: () => import("@views/bookings/Index"),
    meta: { title: "data.menu.bookings.meta.title" },
  },
  {
    path: path + "/new-booking",
    name: "booking_new_index",
    props: true,
    component: () => import("@views/bookings/create-or-update/Index"),
  },
  {
    path: path + "/booking-update/:id",
    name: "booking_update",
    props: true,
    component: () => import("@views/bookings/create-or-update/Index"),
  },
];