<template>
  <div
    id="app"
    :class="inAuthView ? '' : 'zoom-90'"
  >
    <router-view />
  </div>
</template>

<script>
import Bus from "@/utils/bus";
import { DANGER } from "@plugins/flash";
import { appendChatBoxScript } from "@/utils/hubspot";
import { getActualities } from "@api/doinsport/services/actualities/actualities.api";

export default {
  name: "App",
  computed: {
    inAuthView () {
      return this.$route.name === "Login" || this.$route.name === "password-recovery";
    },
  },
  beforeCreate () {
    this.$store.commit("layout/setWidth", window.innerWidth);
  },
  created () {
    appendChatBoxScript();
  },
  beforeDestroy () {
    try {
      Bus.$off("on:400-errors-handled");
    } catch (err) {
      console.error(err);
    }
  },
  mounted () {
    this.$nextTick(function () {
      window.setInterval(() => {
        this.$bus.$emit("load:recent-accesses");
      }, 10000);
    });

    Bus.$on("on:400-errors-handled", (error) => {
      if ("undefined" !== typeof error.response.data.violations) {
        this.$store.dispatch("validator/check", error.response.data);
        for (const violation of error.response.data.violations) {
          this.$flash(null, violation.message);
        }
      } else {
        this.$flash(null, error.response.data["hydra:description"]);
      }
    });

    Bus.$on("on:403-errors-handled", () => {
      this.$flash(null, this.$t("errors.error-403.description"), 8000, DANGER);
    });

    window.addEventListener("resize", () => {
      this.$store.commit("layout/setWidth", window.innerWidth);
    });
  },
};
</script>
<style lang="scss">
.zoom-90 {
  zoom: 0.80;
}

@media only screen and (max-width: 700px) {
  body div#hubspot-messages-iframe-container {
    display: none !important;
  }
}

</style>