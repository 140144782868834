export default {
  getCustomerStats: (state) => {
    return state.customerStats;
  },

  getClubJauge: (state) => {
    return state.clubJauge;
  },
  isLoadingJauge: (state) => {
    return state.isLoadingJauge;
  },
};