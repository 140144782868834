import { capitalize } from "@/utils/string";

export const refine = (model) => {
  for (const key of Object.keys(model)) {
    if ("undefined" !== typeof model[key] && null !== model[key] && model[key].hasOwnProperty("@id")) {
      model[key] = model[key]["@id"];
    }
    if ("undefined" !== typeof model[key] && Array.isArray(model[key])) {
      const arrTmp = model[key];
      model[key] = [];
      for (const k of arrTmp) {
        if ("undefined" !== typeof k["@id"]) {
          model[key].push(k["@id"]);
        }
      }
    }
  }
};

export const arrayMap = (obj, func) => {
  const tmpObj = Object.entries(obj).reduce(
    (prev, [key, value]) => ({
      ...prev,
      [key]: func(key, value),
    }),
    {},
  );

  return Object.values(tmpObj);
};

export const isNotUndefinedAndNotNull = (element) => {
  return typeof element !== "undefined" && element !== null;
};

export const isUndefinedOrNull = (element) => {
  return typeof element === "undefined" || element === null;
};

export const insertIntoArray = (array, index, item) => {
  const newArray = JSON.parse(JSON.stringify(array));
  newArray.splice(index, 0, item);

  return newArray;
};

export const formatRow = (object) => {
  for (const key of Object.keys(object)) {
    object[`${object.constructor.name.toLowerCase()}${capitalize(key)}`] = object[key];
  }
};

export const OPERATIONS = ["gte", "gt", "lte", "lt"];