<template>
  <div class="container-fluid">
    <b-row class="mb-4">
      <b-col
        class="d-flex"
        :class="from ? 'justify-content-left mt-3 mb-3' : 'justify-content-center'"
      >
        <span class="settings-title">
          <i class="icofont icofont-imac" />
          {{ $t('caisse.register.opening-register') }} {{ session.username }}
        </span>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col class="d-flex align-items-center">
        <span class="simple-label-avenir w-50">
          {{ $t('caisse.register.cash-in-hand') }}
        </span>
        <price-text-field
          v-model.number="cashInHand"
          class="w-100"
          :rules="validation.double"
          type="number"
          step="0.2"
          min="0"
          size="sm"
          error-text="validation.required"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col align="middle">
        <d-button
          text="general.actions.validate"
          icon="fa fa-check"
          class="d-btn btn d-btn-primary-new font-text-title"
          @on:button-click="onValidate"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PriceTextField from "@custom/shop/PriceTextField";
import { getRegister, newRegister } from "@api/nextore/services/registry.api";

export default {
  components: { PriceTextField },
  props: {
    from: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    cashInHand: null,
    validation: require("@validation/entities/doinsport/Default.json"),
  }),
  computed: {
    session () {
      return this.$store.getters["cashRegistry/getSession"];
    },
  },
  methods: {
    onValidate () {
      const data = {
        user_id: this.session.id,
        cash_in_hand: this.cashInHand,
      };

      newRegister(data)
        .then((response) => {
          getRegister(response.data)
            .then((response)=> {
              this.$store.commit("cashRegistry/setRegister", response.data[0]);
              this.$emit("on:register:created");
            });
        })
      ;
    },
  },
};
</script>