const path = "events";

export default [
  {
    path: path,
    name: "events",
    component: () => import("@views/events/Index"),
    meta: { title: "data.menu.events.meta.title" },
  },
  {
    path: path + "/:id",
    name: "event",
    component: () => import("@views/events/event-details/Index"),
  },
];