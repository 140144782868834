const path = "users";

export default [
  {
    path: path,
    name: "users",
    component: () => import("@views/top-bar/users/Index"),
  },
  {
    path: `${path}/update-profile/:id`,
    name: "update-profile",
    component: () => import("@views/top-bar/users/profiles-accesses/Update"),
  },
];