import Vue from "vue";
import { isNotUndefinedAndNotNull } from "@/utils/classes";

const DEFAULT_DELAY = 5000;
const DEFAULT_POSITION = "top-right";
export const SUCCESS = "success";
export const DANGER = "danger";

const flashPlugin = {
  fire (title, message, delay, type, position) {
    const notification = {
      color: isNotUndefinedAndNotNull(type) ? type : DANGER,
      time: isNotUndefinedAndNotNull(delay) ? delay : DEFAULT_DELAY,
      position: isNotUndefinedAndNotNull(position) ? position : DEFAULT_POSITION,
      title: isNotUndefinedAndNotNull(title) ? title : this.$t("general.actions.notification"),
      text: isNotUndefinedAndNotNull(message) ? message : this.$t("general.actions.error-notification"),
    };

    this.$vs.notify(notification);
  },

  install (Vue) {
    Vue.prototype.$flash = this.fire;
  },
};

Vue.use(flashPlugin);

export default flashPlugin;