const path = "live-accesses";

export default [
  {
    path: path,
    name: "live-accesses",
    component: () => import("@views/accesses/Index"),
  },
  {
    path: path + "/list",
    name: "accesses-list",
    component: () => import("@views/accesses/List"),
  },
];