import httpNextore from "@api/nextore/httpNextore";

const encodeQuery = require("querystring");

export const _get = (URL) => {
  return httpNextore.get(URL)
    .then(response => {
      return new Promise((resolve) => {
        resolve(response);
      });
    })
    .catch((error) => {
      return Promise.reject(error);
    })
  ;
};

export const _post = (URL, data, encode = true) => {
  const encodedData = encode ? encodeQuery.stringify(data) : data;

  return httpNextore.post(URL, encodedData)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    })
  ;
};

export const _put = (URL, data, encode = true) => {
  const encodedData = encode ? encodeQuery.stringify(data) : data;

  return httpNextore.put(URL, encodedData)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    })
  ;
};

export const _delete = (URL) => {
  return httpNextore.delete(URL)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch((error) => {
      return Promise.reject(error);
    })
  ;
};