<template>
  <b-modal
    :id="modalId"
    body-bg-variant="gray-lighten"
    size="lg"
    hide-header
    hide-footer
    hide-header-close
  >
    <open-register-form
      v-if="!hasRegister"
      @on:register:created="$bvModal.hide(modalId)"
    />
    <register-details
      v-else
      @hide="$bvModal.hide(modalId)"
    />
  </b-modal>
</template>
<script>

import OpenRegisterForm from "@form/caisse/register/OpenRegisterForm";
import { isNotUndefinedAndNotNull } from "@/utils/classes";
import { getRegister } from "@api/nextore/services/registry.api";
import RegisterDetails from "@custom/caisse/registry/RegisterDetails";

export default {
  components: {
    RegisterDetails,
    OpenRegisterForm,
  },
  props: {
    modalId: {
      type: String,
      default: "CASH_IN_HAND_MODAL",
    },
    display: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({ serverRegister: null }),
  computed: {
    session () {
      return this.$store.getters["cashRegistry/getSession"];
    },
    register () {
      return this.$store.getters["cashRegistry/getRegister"];
    },
    registers () {
      return this.$store.getters["cashRegistry/getActiveRegisters"];
    },
    hasRegister () {
      if (this.session) {
        const findRegister = this.registers.find((el) => el.user_id === this.session.id);

        if (isNotUndefinedAndNotNull(findRegister) && findRegister.id !== this.register.id) {
          this.$store.commit("cashRegistry/switchRegister", findRegister);
        }

        return isNotUndefinedAndNotNull(findRegister);
      }

      return false;
    },
  },
  watch: {
    display: function () {
      this.$bvModal.show(this.modalId);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@lazy/_modal.scss";
</style>