import { _get, _post, _put } from "@api/nextore/services/httpNextoreService";

const REGISTRY_URL = "registers";

export const getOpenRegistries = () => _get(`${REGISTRY_URL}?status=open`);

export const newRegister = (data) => _post(`${REGISTRY_URL}`, data);

export const getRegister = (id) => _get(`${REGISTRY_URL}?register_id=${id}`);

export const putRegister = (data) => {
  return _put(REGISTRY_URL, data, true);
};