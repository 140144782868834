import { isNotUndefinedAndNotNull } from "@/utils/classes";

const session = localStorage.getItem("nextoreSession");
const nextoreSession = isNotUndefinedAndNotNull(session) ? JSON.parse(session) : {};

const register = localStorage.getItem("nextoreRegister");
const nextoreRegister = isNotUndefinedAndNotNull(register) ? JSON.parse(register) : {};

const registers = localStorage.getItem("nextoreRegisters");
const nextoreRegisters = isNotUndefinedAndNotNull(registers) ? JSON.parse(registers) : [];

const activeSessions = localStorage.getItem("activeSessions");
const doinsportSessions = isNotUndefinedAndNotNull(activeSessions) ? JSON.parse(activeSessions) : [];

const activeRegisters = localStorage.getItem("activeRegisters");
const doinsportRegisters = isNotUndefinedAndNotNull(activeRegisters) ? JSON.parse(activeRegisters) : [];

export default {
  sessions: [],
  blockPrices: [],
  tickets: [],
  session: nextoreSession,
  register: nextoreRegister,
  registers: nextoreRegisters,
  activeSessions: doinsportSessions,
  activeRegisters: doinsportRegisters,
  displaySessionsModal: true,
  displayNoRegisterModal: false,
  displayNoCashRegisterModal: false,
  displayExpiredRegisterModal: false,
  displayCashRegistriesModal: false,
};