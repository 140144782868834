export default {
  setStartDate: (context, payload) => {
    context.commit("setStartDate", payload);
  },
  setEndDate: (context, payload) => {
    context.commit("setEndDate", payload);
  },
  setCurrentPage: (context, payload) => {
    context.commit("setCurrentPage", payload);
  },
};