<template>
  <b-modal
    v-model="display"
    class="m-0"
    hide-header
    hide-footer
    hide-header-close
    no-close-on-esc
    no-close-on-backdrop
    header-class="my-second-class"
    body-bg-variant="gray-lighten"
  >
    <b-row>
      <b-col
        align="center"
        class="modal-title-class"
      >
        {{ $t(`caisse.register.payments.close-modal-title`) }}
      </b-col>
    </b-row>
    <div class="border-bottom-grey-dark mt-3 mb-3" />
    <b-row>
      <b-col>
        {{ $t('caisse.register.payments.expired-register-description') }}
      </b-col>
    </b-row>
    <div class="border-bottom-grey-dark mt-3 mb-3" />
    <b-row>
      <b-col align="middle">
        <d-button
          text="general.actions.dismiss"
          class="d-btn-sm d-btn btn d-btn-danger font-text-title mt-2 mr-2"
          icon="fa fa-exclamation-triangle"
          @on:button-click="hideModal"
        />
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
export default {
  props: {
    modalId: {
      type: String,
      default: "EXPIRED_REGISTER_MODAL_ID",
    },
  },
  computed: {
    display () {
      return this.$store.getters["cashRegistry/getDisplayExpiredRegisterModal"];
    },
  },
  methods: {
    hideModal () {
      this.$store.commit("cashRegistry/setDisplayExpiredRegisterModal", false);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@lazy/_modal.scss";
</style>