<template>
  <a :href="mailto" target="_blank" class="d-flex align-items-center justify-content-center">
    <span class="icons-nav-size text-danger fa fa-envelope pointer mr-1" />
    <label class="mb-0 pointer guide-color">
      <span class="d-none d-xl-block">
        {{ $t("components.core.header.support-contact") }}
      </span>
      <span class="d-xl-none">{{ $t("components.core.header.support") }}</span>
    </label>
  </a>
</template>

<script>
import { mapGetters } from "vuex";

const BREAKLINE = "%0D%0A";

export default {
  data () {
    return { email: "support@doinsport.com" };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      currentClub: "auth/currentClub",
    }),
    mailto () {
      return `mailto:${this.email}?subject=${this.subject}&body=${this.body}`;
    },
    subject () {
      return `[${this.currentClub.name}] Demande de support`;
    },
    body () {
      return `${BREAKLINE}
        ${BREAKLINE} ----------------------------------------
        ${BREAKLINE} CLUB : ${this.clubInfos}
        ${BREAKLINE} USER : ${this.userInfos}
        ${BREAKLINE} URL : ${window.location.href}
        ${BREAKLINE} UA : ${navigator.userAgent}
        ${BREAKLINE} ----------------------------------------`;
    },
    clubInfos () {
      const fixPhoneNumber = encodeURIComponent(this.currentClub.fixPhoneNumber);
      const mobilePhoneNumber = encodeURIComponent(this.currentClub.mobilePhoneNumber);
      return `${this.currentClub.name} (${fixPhoneNumber} / ${mobilePhoneNumber})`;
    },
    userInfos () {
      const phoneNumber = this.currentUser.phoneNumber ? "(" + encodeURIComponent(this.currentUser.phoneNumber) + ")" : "";
      return `${this.currentUser.firstName} ${this.currentUser.lastName} ${phoneNumber}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@lazy/menu/_header-menu.scss";
</style>