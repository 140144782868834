import { getUserAccesses } from "@api/doinsport/services/user-club/user-club.api";
const userPermissions = require("@/data/permissions.json");

export default {
  getApiUserPermissions: (context, userId) => {
    return new Promise((resolve, reject) => {
      getUserAccesses(userId)
        .then((response) => {
          context.commit("setUserPermissions", response.data.allowedPermissions);
          resolve(response.data.allowedPermissions);
        })
        .catch(() => {
          context.commit("setUserPermissions", userPermissions.permissions);
          resolve(userPermissions.permissions);
        })
      ;
    });
  },
};