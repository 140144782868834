<template>
  <div
    :key="'header' + componentKey"
    :class="{ open:toggle_sidebar_var }"
    class="page-main-header"
  >
    <div
      class="main-header-right row"
    >
      <ul
        v-if="innerWidth > 761"
        class="col-md-4"
      >
        <li class="logo-wrap pointer">
          <div
            v-if="innerWidth > 761"
          >
            <img
              :src="require('@/assets/images/logo/doinsport-logo.svg')"
              alt
              width="55"
              height="40"
              @click="goToPlanning"
            >
          </div>
        </li>
        <li class="pl-4">
          <img
            v-if="logo || clubLogo"
            id="clubLogoId"
            class="screen-reset"
            width="55"
            height="40"
            :src="clubLogo"
          >
          <clubs-selection
            :club-title="clubTitle"
          />
        </li>
      </ul>
      <div
        v-show="$route.name === 'planning'"
        :class="innerWidth > 761 ? 'd-flex justify-content-center col-md-4' : ''"
      >
        <div
          v-if="innerWidth > 761 && $route.name === 'planning'"
          class="label-club-font planning-day-label f-w-700 d-flex flex-column align-items-center"
        >
          {{ planningDate }}
          <occupancy-rate-display
            class="global-jauge"
            :value="clubJauge"
            :loading="isLoadingJauge"
            @click.native="goToOccupancyReport"
          />
        </div>
      </div>
      <div
        class=" d-flex align-items-center align-content-center  mb-0 pr-0 "
        :class="getStyles"
      >
        <ul class="nav-menus display-content" :class="{ open: mobiletoggle2}">
          <li v-if="innerWidth <= 761">
            <i
              id="burger-icon-id"
              class="icofont icofont-navigation-menu hamburger-logo"
              @click="$emit('on:toggle-click')"
            />
          </li>
          <li v-if="innerWidth <= 761">
            <clubs-selection
              :club-title="clubTitle"
            />
          </li>
          <li v-if="innerWidth <= 761">
            <div class="label-club-font planning-day-label f-w-700 mr-2">
              {{ planningDate }}
            </div>
          </li>
          <li class="mr-2">
            <support-link />
          </li>
          <li
            class="pointer d-flex align-items-center"
            @click="goToLink(SUPPORT_LINK)"
          >
            <i class="icofont icofont-light-bulb guide-icon" />
            <label class="mb-0 pointer guide-color">
              {{ $t('components.core.header.guide') }}
            </label>
          </li>
          <li class="p-0 m-0">
            <b-nav-item-dropdown
              class="p-0 m-0 lang-btn"
            >
              <template slot="button-content">
                <img
                  width="18px"
                  height="18px"
                  :src="require(`@/assets/images/flags/${$i18n.locale}.png`)"
                >
              </template>
              <b-dropdown-item
                v-for="(lang, i) in langs"
                :key="i"
                @click="switchLocale(lang.img)"
              >
                <img
                  width="20px"
                  :src="require(`@/assets/images/flags/${lang.img}.png`)"
                >
                {{ lang.lang }}
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </li>
          <li
            v-if="$store.getters['auth/currentClub'].cashRegisterType === 'nextore'"
            class="border-left"
          >
            <nextore-session />
          </li>
          <li class="text-danger border-left pointer">
            <span
              class="ml-2 mr-2 fa-1x5 fa fa-youtube"
              @click="goToLink(YOUTUBE_PLAYLIST_LINK)"
            />
          </li>
          <li class="border-left border-right">
            <settings-selection />
          </li>
          <li
            :class="innerWidth <= 800 ? 'mr-0' : 'mr-2'"
          >
            <span
              class="ml-2 mt-1 mr-2 icons-size-disconnect fa fa-power-off pointer text-danger"
              @click="logout()"
            />
          </li>
          <li>
            <div class="d-flex align-items-center user-name">
              <span class="user-label media-body">
                <span
                  class="mr-2 mt-2"
                >
                  {{ firstName }} {{ lastName }}
                </span>
                <span class="d-block">
                  {{ userName }}
                </span>
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <notification />
  </div>
</template>
<script>
import Bus from "@/utils/bus";

import OccupancyRateDisplay from "@/components/custom/stats/occupancy/OccupancyRateDisplay.vue";
import SupportLink from "@/components/custom/support/SupportLink.vue";
import { isNotUndefinedAndNotNull } from "@/utils/classes";
import NextoreSession from "@custom/core/NextoreSession";
import { mapGetters, mapState } from "vuex";
import { SUPPORT_LINK, YOUTUBE_PLAYLIST_LINK } from "@/constants/header";

export default {
  components: {
    NextoreSession,
    Notification: () => import(/* webpackChunkName: "Planning" */"@custom/access/Notification"),
    ClubsSelection: () => import(/* webpackChunkName: "Planning" */"@custom/core/ClubsSelection"),
    SettingsSelection: () => import(/* webpackChunkName: "Planning" */"@custom/core/SettingsSelection"),
    OccupancyRateDisplay,
    SupportLink,
  },
  data () {
    return {
      langs: [
        {
          img: "fr",
          lang: "Français",
        },
        {
          img: "en",
          lang: "English",
        },
        {
          img: "it",
          lang: "Italiano",
        },
        {
          img: "de",
          lang: "Deutsch",
        },
        {
          img: "nl",
          lang: "Nederlands",
        },
        {
          img: "es",
          lang: "Español",
        },
      ],
      firstName: "",
      lastName: "",
      userName: "",
      terms: "",
      SUPPORT_LINK,
      YOUTUBE_PLAYLIST_LINK,
      searchOpen: false,
      searchResult: false,
      searchResultEmpty: false,
      toggle_sidebar_var: false,
      clicked: false,
      toggle_rightsidebar_var: false,
      rightclicked: false,
      mobile_toggle: false,
      mobile_search: false,
      mobiletoggle1: false,
      mobiletoggle2: false,
      componentKey: 1,
      logo: "",
      title: "",
    };
  },
  computed: {
    ...mapGetters({
      clubJauge: "stats/getClubJauge",
      isLoadingJauge: "stats/isLoadingJauge",
      innerWidth: "layout/getInnerWidth",
    }),
    getStyles () {
      if (this.innerWidth >= 761) {
        return "col-md-4 justify-content-end";
      } else if (this.innerWidth < 761) {
        return "col-md-12 justify-content-between pl-0";
      }
      return "justify-content-end ";

    },
    planningDate () {
      if (this.$route.name === "planning") {
        const date = this.$store.getters["planning/getDate"];
        if (isNotUndefinedAndNotNull(date)) {
          if (this.innerWidth <= 1070) {
            return this.$moment.utc(date).format("DD/MM/YY");
          }
          const day = this.$moment.utc(date).format("dddd");
          return day.charAt(0).toUpperCase() + day.slice(1) + " " + this.$moment.utc(date).locale(this.$i18n.locale).format("LL").replace(" 00:00", "");

        }
      }
    },
    clubTitle () {
      if (localStorage.getItem("current-club") === null) {
        if (this.innerWidth <= 500) {
          return (this.title).substring(0, 6);
        }
        return this.title;

      }
      if (this.innerWidth <= 500) {
        return (this.$store.getters["auth/currentClub"].name).substring(0, 6);
      }
      return this.$store.getters["auth/currentClub"].name;

    },
    clubLogo () {
      return this.loadLogoUrl();
    },
    ...mapState({
      menuItems: state => state.menu.searchData,
      layout: state => state.layout.layout,
    }),
  },
  mounted () {
    const user = JSON.parse(localStorage.getItem("user"));
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.userName = user.username;
    Bus.$on("on:current-club-loaded", (club) => {
      this.setTitle(club.name);
      this.setLogo(club.logo);
    });
  },
  methods: {
    goToPlanning () {
      if (this.$router.currentRoute.name !== "planning") {
        this.$router.push("/planning");
      }
    },
    goToOccupancyReport () {
      const sheduleDate = this.$store.getters["planning/getDate"];
      this.$router.push({
        name: "fill",
        params: {
          fromDate: sheduleDate,
          toDate: sheduleDate,
        },
      });
    },
    goToLink (link) {
      window.open(link, "_blank");
    },
    loadLogoUrl () {
      const url = this.$store.getters["auth/currentClub"].logo;

      return isNotUndefinedAndNotNull(url) ? this.getLogoPath(url.contentUrl) : null;
    },
    getLogoPath (url) {
      if (! url) {
        return "";
      }
      const uri = process.env.VUE_APP_UPLOAD_FILE_PATH;

      if (uri) {
        return uri.replace("{token}", url);
      }
    },
    setTitle (title) {
      this.title = title;
    },
    setLogo (logo) {
      this.logo = logo;
    },
    getLogo () {
      return this.logo;
    },
    getTitle () {
      return this.title;
    },
    switchLocale (locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale;
        this.$moment.locale(locale);
        localStorage.setItem("language", locale);
        this.$bus.$emit("language-changed", locale);
      }
    },
    searchTerm: function () {
      this.$store.dispatch("menu/searchTerm", this.terms);
    },
    logout () {
      this.$store.dispatch("auth/logout")
        .then(() => {
          this.$store.commit("reset");
          this.$router.replace("/auth/login");
        })
        .catch(err => {
          console.error("Error occured when logging" + err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@lazy/menu/_header-menu.scss";
.user-label {
  font: normal normal 700 14px Avenir;
  letter-spacing: -0.4px;
  color: #4D4F5C;
}
.global-jauge {
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}
</style>