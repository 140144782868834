export default {
  setVariations: (state, payload) => {
    state.variations = payload;
  },
  setIsLoadedVariations: (state, payload) => {
    state.isLoadedVariations = payload;
  },
  setUpdatedStartAt: (state, payload) => {
    state.updatedStartAt = payload;
  },
  setLastBlockPrice: (state, payload) => {
    state.lastBlockPrice = payload;
  },
  setLoadedParticipants: (state, payload) => {
    state.loadedParticipants = payload;
  },
  setFirstLoad: (state, payload) => {
    state.firstLoad = payload;
  },
  setLoadedLessons: (state, payload) => {
    state.loadedLessons = payload;
  },
  setSelectedTab: (state, payload) => {
    state.selectedTab = payload;
  },
};