export default {
  getPlaygrounds: (state) => {
    return state.playgrounds;
  },
  getActivities: (state) => {
    return state.activities;
  },
  getDate: (state) => {
    return state.date;
  },
  getDropDownOverflowEnabled: (state) => {
    return state.dropDownOverflowEnabled;
  },
  getBlocksByDate: (state) => {
    return state.blocksByDate;
  },
  getOperations: (state) => {
    return state.operations;
  },
};