<template>
  <div>
    <div
      v-if="loadedPermissions"
      id="canvas-bookmark"
      :class="layout.settings.sidebar.type"
      class="page-wrapper"
    >
      <div class="page-body-wrapper" :class="layout.settings.sidebar.body_type">
        <div
          :class="[
            transparentbg ? '' : layout.settings.sidebar_backround,
            $store.getters['layout/getInnerWidth'] > 760 ? 'page-sidebar' : 'menu-bar']"
          :sidebar-layout="layout.settings.sidebar_setting"
        >
          <the-header
            @on:toggle-click="toggleSidebar = !toggleSidebar"
          />
        </div>
        <div
          :class="[
            transparentbg ? '' : layout.settings.sidebar_backround,
            $store.getters['layout/getInnerWidth'] > 760 ? 'page-sidebar' : '']"
          :sidebar-layout="layout.settings.sidebar_setting"
        >
          <the-sidebar
            ref="open"
            :toggle-sidebar="toggleSidebar"
            @on:send-data="getData"
          />
        </div>
        <div class="page-body">
          <actualities-banner />
          <transition name="fadeIn" enter-active-class="animated fadeIn">
            <router-view
              id="view-id"
              class="view"
            />
          </transition>
        </div>
      </div>
    </div>
    <checkout-modal
      v-if="notInNextoreRoute"
      :key="'modal-sale'"
      :display="showModal"
      @hidden="onHide"
    />
    <div
      v-if="showOpenModalBtn && notInNextoreRoute"
      class="icon-caisse-modal"
      @click="openCheckoutModal()"
    >
      <img
        :src="require('@/assets/caisse.svg')"
        class="pointer ml-2 mr-2"
        width="20px"
      >
    </div>
  </div>
</template>

<script>
import TheHeader from "./TheHeader.vue";
import TheSidebar from "./sidebar/TheSidebar.vue";
import ActualitiesBanner from "@/components/custom/actuality/ActualitiesBanner.vue";
import { mapState } from "vuex";

export default {
  components: {
    TheHeader,
    TheSidebar,
    CheckoutModal: () => import(/* webpackChunkName: "Planning" */"@views/Nextore/CheckoutModal"),
    ActualitiesBanner,
  },
  data () {
    return {
      loadedPermissions: false,
      display: false,
      horizontal_Sidebar: true,
      resized: false,
      toggleSidebar: false,
    };
  },
  computed: {
    notInNextoreRoute () {
      return this.$route.name !== "caisse_index";
    },
    showModal () {
      return this.$store.getters["nextore/showModal"];
    },
    showOpenModalBtn () {
      return this.$store.getters["nextore/getProducts"] ? (this.$store.getters["nextore/getProducts"].length > 0) : false;
    },
    ...mapState({
      menuItems: state => state.menu.data,
      layout: state => state.layout.layout,
      user: state => state.auth.user,
      permissions: state => state.userPermissions.permissions,
      transparentbg: state => state.layout.transparentbg,
    }),
  },
  created () {
    this.loadUserPermissions();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.resized = this.sidebar_toggle_var;
    this.$store.dispatch("layout/set");
  },
  methods: {
    loadUserPermissions () {
      this.loadedPermissions = false;

      this.$store.dispatch("userPermissions/getApiUserPermissions", this.user.id)
        .then(() => {
          this.loadedPermissions = true;
        })
      ;
    },
    onHide () {
      this.$store.dispatch("nextore/showModal", false);
    },
    openCheckoutModal () {
      this.$store.dispatch("nextore/showModal", true);
    },
    getData (data) {
      const bodyEl = document.getElementById("view-id");

      if (this.$store.getters["layout/getInnerWidth"] < 760) {
        if (data === true) {
          bodyEl.classList.add("add-margin");
        } else {
          bodyEl.classList.remove("add-margin");
        }
      }
    },
    handleResize () {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@lazy/_body.scss";

.icon-caisse-modal {
  background-color: #0b1620;
  position: fixed;
  bottom: 100px;
  right: 29px;
  border-radius: 50%;
  min-width: 60px;
  min-height: 60px;
  max-width: 60px;
  max-height: 60px;
  box-shadow: 0 0 10px 10px rgba(145, 145, 145, 0.3);
  display: flex;
  align-items: center;
  text-align: center;
  padding: 15px;

  img {
    margin: 0 !important;
    display: block;
    text-align: center;
    color: white;
    width: 100%;
    height: auto;

    &path {
      fill: white;
    }
  }
}
</style>